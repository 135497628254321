<template>
  <div>
    <section class="w-full">
      <header-register
      :showButtonView="false"
      >{{ $t("sectionAdmCartoes.cardAdministrator") }}
    </header-register>
    <main class="mt-3">
      <RegisterCard />
    </main>
    </section>
  </div>
</template>

<script>
  import RegisterCard from '@/components/administratorCard/components/Create/RegisterCard.vue';
  import HeaderRegister from "@core/components/register/HeaderRegister.vue";

  export default {
    components: {
      RegisterCard,
      HeaderRegister
    },
    data() {
      return {
      };
    },
  }
</script>